export const config = {
    namespaced: true,
    state: () => ({
        url: null,
        key: null,
        secret: null,
    }),
    mutations: {
        setUrl(state, value) {
            state.url = value;
        },
        setKey(state, value) {
            state.key = value;
        },
        setSecret(state, value) {
            state.secret = value;
        }
    },
    actions: {},
    getters: {
        url(state) {
            return state.url;
        },
        key(state) {
            return state.key;
        },
        secret(state) {
            return state.secret;
        }
    }
}