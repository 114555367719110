import {create_order_by_shipping} from '@/services/utils';

export const orders = {
    namespaced: true,
    state: () => ({
        orders: [],
        ordersByShippingDate: null
    }),
    mutations: {
        setOrders(state, orders) {
            state.orders = orders;
        },
        setOrdersByShippingDate(state, ordersByShippingDate) {
            state.ordersByShippingDate = ordersByShippingDate;
        }
    },
    actions: {
        classifyOrdersByDate({getters, commit}) {
            let ordersByShippingDate = []
            if (getters.orders.length > 0) {
                ordersByShippingDate = create_order_by_shipping(getters.orders)
            }
            commit('setOrdersByShippingDate', ordersByShippingDate)
        }
    },
    getters: {
        orders(state) {
            return state.orders;
        },
        ordersByShippingDate(state) {
            return state.ordersByShippingDate;
        }
    }
}