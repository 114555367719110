<template>
  <v-app>
    <v-app-bar
        app
        color="primary"
        dark
        elevation="0"

    >
      <v-toolbar-title>{{ this.$route.path.toString().toUpperCase() }}</v-toolbar-title>
      <v-spacer></v-spacer>

      <v-toolbar-title v-if="!$store.getters.appLoading">last update :
        {{ $store.getters.lastUpdate }}
      </v-toolbar-title>

      <v-spacer></v-spacer>

    </v-app-bar>

    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import wooConfig from '@/services/config/woo-config';

export default {
  name: 'App',
  methods: {
    setConf() {
      //wooConfig.setWooConfig('http://localhost:8081/wp-json/wc/v3', 'ck_2427234b0eedd38ef2ada1f64f865aa1e8124f15', 'cs_a5cdb7418628482b2d9d0a37122f7fd27f8e057b')
      wooConfig.setWooConfig('https://click-collect.les-dunes.fr/wp-json/wc/v3', 'ck_e1cfe43855c8e60b7b7d4ccd90c6a65f6b3c2744', 'cs_6e6d5c577875e8874352149d384e84f79281023e')
    }
  },
  created() {
    this.setConf();
    this.$router.push("/events")
  },
};
</script>
<style scoped>
.v-div-perso {
  margin-bottom: 75px;
}
</style>
