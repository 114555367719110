<template>
  <div>
    <h1>{{ this.$route.params.date }}</h1>
    <v-data-table
        v-if="ordersByThisShippingDate[0].order"
        :headers="$store.getters.headersOrders"
        :items="ordersByThisShippingDate[0].order"
        class="elevation-1"
        hide-default-footer
        :items-per-page="ordersByThisShippingDate[0].order.length"
        v-model="ordersByThisShippingDate[0].order"
        @click:row="handleClick"
    ></v-data-table>
  </div>
</template>

<script>

export default {
  name: "Orders",
  data: () => ({}),
  computed: {
    ordersByThisShippingDate: function () {
      let self = this;
      return this.$store.getters["orders/ordersByShippingDate"].filter(function (order) {
        return order.date === self.shippingDate
      })
    },
    shippingDate: function () {
      return this.$route.params.shippingDate
    }
  },
  methods: {
    handleClick(order) {
      this.$router.push({
        name: `products.order`,
        params: {products: order.line_items, shippingDate: this.shippingDate, orderId: order.id}
      });
    }
  },
  created() {
  }
};
</script>

<style scoped>

</style>