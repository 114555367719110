import Router from 'vue-router'
import Vue from "vue";
import Events from '@/components/Events';
import Products from "@/components/Products";
import Orders from "@/components/Orders";
import App from "@/App";
import { publicPath } from '../../vue.config'

Vue.use(Router)

export default new Router({
    mode: "history",
    base: publicPath,
    routes: [
        {
            path: '/events',
            name: 'Events',
            component: Events
        },
        {
            path: '/orders/:shippingDate',
            name: 'Orders',
            component: Orders,
            props: true,
        },
        {
            path: '/orders/:shippingDate/:orderId',
            name: 'products.order',
            component: Products,
            props: true
        },
        {
            path: '/products/:shippingDate',
            name: 'products.event',
            component: Products,
            props: true
        },
        {
            path: '*',
            name: 'App',
            component: App
        },

    ]
})