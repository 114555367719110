import axios from 'axios';
import store from '@/store/store';

const client = axios.create();
let endAuth = '?consumer_key=' + store.getters["config/key"] + '&consumer_secret=' + store.getters["config/secret"]
let url = store.getters["config/url"]

store.subscribe((mutation) => {
    if (mutation.type === 'config/setSecret') {
        endAuth = '?consumer_key=' + store.getters["config/key"] + '&consumer_secret=' + store.getters["config/secret"]
        url = store.getters["config/url"]
    }
})

function getProducts() {
    return client.get(url + '/products' + endAuth)
        .then(function (response) {
            store.commit('setProducts', response.data);
        })
        .catch(function (error) {
            return error;
        })
}

function getProductId(id) {
    return client.get(url + '/products/' + id + endAuth)
        .then(function (response) {
            store.commit('pushProduct', response.data);
        })
        .catch(function (error) {
            return error;
        })
}

function getOrders() {
    return client.get(url + '/orders' + endAuth)
        .then(function (response) {
            store.commit('setOrders', response.data);
            store.commit('setSelectedOrders', response.data);
        })
        .catch(function (error) {
            return error;
        })
}

function retrieveOrders(){
    return client.get(url + '/orders' + endAuth).then(function (response){
        return response.data;
    }).catch(function (error){
        return error;
    })
}

export default {
    getProducts, getProductId, getOrders, retrieveOrders
}