import { organize_reports_products } from '@/services/utils';

export const reports = {
    namespaced: true,
    state: () => ({
        tab: null,
        items: [
            'orders', 'products'
        ],
        products: []
    }),
    mutations: {
        setTab(state, new_value) {
            state.tab = new_value;
        },
        setProducts(state, new_value) {
            state.products = new_value;
        }
    },
    actions: {
        setProducts() {
            organize_reports_products()
        }
    },
    getters: {
        tab(state) {
            return state.tab;
        },
        items(state) {
            return state.items
        },
        products(state) {
            return state.products;
        }
    }
}