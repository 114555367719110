import axios from 'axios';
import addOAuthInterceptor from "axios-oauth-1.0a";
import store from '@/store/store';

const client = axios.create();

function setOAuth() {
    addOAuthInterceptor(client, {
        // OAuth consumer key and secret
        key: store.getters["config/key"],
        secret: store.getters["config/secret"],

        // HMAC-SHA1 and HMAC-SHA256 are supported
        algorithm: "HMAC-SHA1",
    });
}


function getProducts() {
    return client.get(store.getters["config/url"] + '/products/')
        .then(function (response) {
            store.commit('setProducts', response.data);
        })
        .catch(function (error) {
            return error;
        })
}

function getProductId(id) {
    return client.get(store.getters["config/url"] + '/products/' + id)
        .then(function (response) {
            store.commit('pushProduct', response.data);
        })
        .catch(function (error) {
            return error;
        })
}

function getOrders() {
    return client.get(store.getters["config/url"] + '/orders/')
        .then(function (response) {
            store.commit('setOrders', response.data);
            store.commit('setSelectedOrders', response.data);
        })
        .catch(function (error) {
            return error;
        })
}

function retrieveOrders() {
    return client.get(store.getters["config/url"] + '/orders/')
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            return error;
        })
}

export default {
    getProducts, getProductId, getOrders, setOAuth, retrieveOrders
}