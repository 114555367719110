<template>
  <div>
    <v-data-table
        v-if="this.$props.products"
        :headers="headersProducts"
        :items="this.$props.products"
        class="elevation-1"
        hide-default-footer
        :items-per-page="this.$props.products.length"
    ></v-data-table>
    <v-card class="mx-auto"
            max-width="344"
            style="margin: 50px">
      <v-card-text>

        <v-row align="center">
          <v-col cols="5">
            Command
            <p class="text-h6 text--primary">
              {{ this.$route.params.orderId }}
            </p>
          </v-col>
          <v-col cols="7">
            Total
            <p class="text-h6 text--primary">
              {{ this.getTotal() }} €
            </p>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import {get_total_products_price} from "@/services/utils";

export default {
  name: "Products",
  props: ['products'],
  computed: {
    headersProducts() {
      return this.$store.getters.headersProducts;
    }
  },
  data: () => ({}),
  methods: {
    getTotal() {
      return get_total_products_price(this.$props.products);
    }
  },
  created() {
  }
};
</script>

<style scoped>

</style>