const product = {
    obj: null,
    qty: null
}

import store from '@/store/store';

function organize_reports_products() {
    let items = [];
    let temp = [];
    store.getters.selectedOrdersByDate.forEach(order => {
            order.line_items.forEach(item => {
                items.push(item);
            })
        }
    )
    items.forEach(item => {
        let find = false;
        temp.forEach(tmp => {
            if (tmp.obj.product_id === item.product_id && tmp.obj.variation_id === item.variation_id) {
                tmp.qty += item.quantity;
                find = true
            }
        })
        if (find === false) {
            let new_item = Object.create(product)
            new_item.obj = item;
            new_item.qty = item.quantity;
            temp.push(new_item)
        }
    })
    store.commit('reports/setProducts', temp);
}

function create_order_by_shipping(orders) {
    let ordersByShippingDate = [];
    for (let i = 0; i < orders.length; i++) {
        Object.entries(orders[i].shipping_lines[0].meta_data[0]).forEach(
            ([key, value]) => {
                if (key === "value") {
                    let date = new Date(value * 1000);
                    let organizeObject = {
                        "date": date.toISOString().slice(0, 10),
                        "order": [orders[i]]
                    }
                    let found = false
                    ordersByShippingDate.forEach(obj => {
                        if (obj.date === date.toISOString().slice(0, 10)) {
                            obj.order.push(orders[i])
                            found = true
                        }
                    })
                    if (found !== true) {
                        ordersByShippingDate.push(organizeObject)
                    }
                }
            }
        );
    }
    return ordersByShippingDate
}


function get_total_products_price(products) {
    let total = 0;
    products.forEach(product => {
        total += parseFloat(product.subtotal)
    })
    return total
}

function organize_products_from_orders(orders) {
    let items = [];
    let temp = [];
    orders.forEach(order => {
            order.line_items.forEach(item => {
                items.push(item);
            })
        }
    )
    items.forEach(item => {
        let find = false;
        temp.forEach(tmp => {
            if (tmp.product_id === item.product_id && tmp.variation_id === item.variation_id) {
                tmp.quantity += item.quantity;
                tmp.subtotal += parseFloat(item.subtotal);
                find = true
            }
        })
        if (find === false) {
            item.subtotal = parseFloat(item.subtotal)
            temp.push(item)
        }
    })
    return temp;
}

export {
    organize_reports_products,
    create_order_by_shipping,
    get_total_products_price,
    organize_products_from_orders
}