import Vuex from 'vuex'
import Vue from "vue";
import {reports} from "@/store/reports";
import {config} from '@/store/config';
import {navigation} from "@/store/navigation";
import {orders} from "@/store/orders";


Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        reports,
        config,
        navigation,
        orders
    },
    state: {
        appLoading: true,
        lastUpdate: null,
        products: [],
        //orders: [],
        selectedOrders: [],
        selectedOrdersByDate: [],
        productBySelectedOrderByDate: [],
        navigationValue: 'orders',
        error: '',
        datePickerDatas: {
            date: new Date().toISOString().substr(0, 10),
            dateDialog: true,
        },
        headersOrders: [
            {text: "number", value: 'number'},
            {text: 'firstname', value: 'billing.first_name'},
            {text: 'lastname', value: 'billing.last_name'},
            {text: 'date_created', value: 'date_created'},
            {text: 'total', value: 'total'},
            {text: 'status', value: 'status'},
        ],
        headersProducts: [
            {text: 'Product', value: 'name'},
            {text: "Unit price", value: 'price'},
            {text: 'Quantity', value: 'quantity'},
            {text: 'Sub Total', value: 'subtotal'}
        ]
    },
    getters: {
        appLoading(state) {
            return state.appLoading;
        },
        lastUpdate(state) {
            return state.lastUpdate;
        },
        getProducts(state) {
            return state.products;
        },
        getOrders(state) {
            return state.orders;
        },
        navigationValue(state) {
            return state.navigationValue;
        },
        selectedOrders(state) {
            return state.selectedOrders;
        },
        datePickerDatas(state) {
            return state.datePickerDatas;
        },
        selectedOrdersByDate(state) {
            return state.selectedOrders.filter(o => o.date_created > state.datePickerDatas.date);
        },
        productBySelectedOrderByDate(state) {
            return state.productBySelectedOrderByDate;
        },
        headersOrders(state) {
            return state.headersOrders;
        },
        headersProducts(state){
            return state.headersProducts;
        }
    },
    mutations: {
        setAppLoading(state, value) {
            state.appLoading = value;
        },
        setLastUpdate(state) {
            state.lastUpdate = new Date().toLocaleTimeString();
        },
        setProducts(state, products) {
            state.products = products;
        },
        setOrders(state, orders) {
            state.orders = orders;
        },
        setError(state, error) {
            state.error = error;
        },
        navigationValue(state, value) {
            state.navigationValue = value;
        },
        setSelectedOrders(state, value) {
            state.selectedOrders = value;
        },
        setDatePickerDate(state, value) {
            state.datePickerDatas.date = value;
        },
        setDatePickerDateDialog(state, value) {
            state.datePickerDatas.dateDialog = value;
        },
        setProductBySelectedOrderByDate(state, value) {
            state.productBySelectedOrderByDate = value;
        }
    },
    actions: {
        setDatePickerDialog(context) {
            if (this.state.datePickerDatas.dateDialog) {
                context.commit('setDatePickerDateDialog', false)
            } else {
                context.commit('setDatePickerDateDialog', true)
            }
        }
    }
})