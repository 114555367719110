export const navigation = {
    namespaced: true,
    state: () => ({
        status: null,
        breadcrumbs: []
    }),
    mutations: {
        setStatus(state, value) {
            state.status = value;
        }
    },
    actions: {
        setStatus(context) {
            context.commit('status');
            context.commit('breadcrumbs');
        }
    },
    getters: {
        status(state) {
            return state.status;
        }
    }
}