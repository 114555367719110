import store from '@/store/store';

function setWooConfig(url, key, secret) {
    store.commit('config/setUrl', url);
    store.commit('config/setKey', key);
    store.commit('config/setSecret', secret);
}

export default {
    setWooConfig,
}