<template>
  <ul>
    <li v-for="order in ordersByDate" :key="order.date" style="list-style-type: none;">
      <CardEvent :shippingDate="order.date" :orders="order.order" style="margin: 20px">
      </CardEvent>
    </li>
  </ul>
</template>

<script>

import CardEvent from '@/components/Cards/Event';
import store from "@/store/store";
import apiHttp from "@/services/requests/woo-axios-OAuth";
import apiHttps from "@/services/requests/woo-axios";
import {mapGetters} from "vuex";

export default {
  name: "Events",
  components: {
    CardEvent
  },
  computed: {
    ...mapGetters({
      ordersByDate: "orders/ordersByShippingDate"
    })
  },
  data: () => ({}),
  methods: {
    retrieveOrders: async function () {
      this.$store.commit('setAppLoading', true);
      if (store.getters["config/url"].startsWith('https')) {
        let data = await apiHttps.retrieveOrders().catch(function (error) {
          alert(error)
        });
        this.$store.commit("orders/setOrders", data);
        await this.$store.dispatch("orders/classifyOrdersByDate");
      } else {
        await apiHttp.setOAuth();
        let data = await apiHttp.retrieveOrders().catch(function (error) {
          alert(error)
        });
        this.$store.commit("orders/setOrders", data);
        await this.$store.dispatch("orders/classifyOrdersByDate");
      }

      // set last update value
      this.$store.commit('setLastUpdate');
      await this.$store.dispatch('reports/setProducts', this.$store.getters.getOrders);

      // app is now ready
      this.$store.commit('setAppLoading', false);
    }
  },
  created() {
    this.retrieveOrders();
  }
}
</script>

<style scoped>

</style>