<template>
  <v-card class="mx-auto"
          max-width="400"
          :color="this.getColor()">
    <v-card-title>
      {{ this.$props.shippingDate }}
    </v-card-title>
    <v-card-text>
      Status : {{ this.computedStatus }}
    </v-card-text>
    <v-card-actions v-if="this.haveOrders()">
      <v-btn @click="routeToOrder()">
        Orders
      </v-btn>
      <v-btn @click="routeToProducts()">
        Products
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {organize_products_from_orders} from "@/services/utils"

export default {
  name: "Event",
  props: {
    'shippingDate': String,
    'orders': Array
  },
  computed: {
    computedStatus: function () {
      return (this.shippingDate >= new Date().toISOString().slice(0, 10) ? "Processing" : "Completed")
    }
  },
  methods: {
    haveOrders: function () {
      return (this.orders.length > 0)
    },
    getColor: function () {
      return (this.computedStatus === "Processing" ? "#ffffff" : "#e6e6e6")
    },
    routeToOrder: function () {
      this.$router.push({
        name: 'Orders',
        params: {shippingDate: this.shippingDate, orders: this.orders}
      });
    },
    routeToProducts: function () {
      this.$router.push({
        name: `products.event`,
        params: {
          products: organize_products_from_orders(this.orders),
          shippingDate: this.shippingDate
        }
      });
    }
  }
}
</script>

<style scoped>

</style>